<template>
  <nav
    class="breadcrumb is-small"
    aria-label="breadcrumbs"
    data-translate
  >
    <ul>
      <li>
        <NuxtLink :to="$route.query.is_business ? '/business/top' : '/'">ホーム</NuxtLink>
      </li>
      <template v-if="!isLoading">
        <li
          v-for="bread in bread.breadcrumb"
          :class="{ 'is-active': bread.isCurrent }"
          :key="bread.id"
        >
          <a
            v-if="bread.isCurrent"
            href="javascript:;"
            aria-current="page"
          >
            {{ bread.name }}
          </a>
          <a
            v-else
            :href="addQueryToUrlIfBusinessPreview(bread.url)"
          >
            {{ bread.name }}
          </a>
        </li>
      </template>
    </ul>
  </nav>
</template>
<script>
import businessMixin from '~/mixins/pages/business-mixin'

export default {
  name: 'SearchBreadcrumb',
  mixins: [businessMixin],
  props: {
    bread: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  &.is-small {
    font-size: 12px;
  }

  li {
    overflow: hidden;

    & + li {
      &::before {
        content: '>';
        color: $ds2-color-gray-200;
      }
    }

    a {
      @include link-effect;

      margin-top: 2px;
      color: $ds2-color-gray-900;
    }

    &.is-active {
      a {
        display: block;
        overflow: hidden;
        margin-top: 2px;
        color: $ds2-color-gray-700;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
