import { mapGetters, mapState } from 'vuex'
import { grpc } from '@improbable-eng/grpc-web'

// チャット表示するページは下記に記載する
const DISPLAY_CHAT_PAGE_NAME = Object.freeze([
  'index',
  'mypage-dashboard',
  'search',
  'categories-categoryId',
  'services-id',
  'users',
  'users-search',
  'users-id',
  'customize-requests-add-userId',
  'customize-requests-add-userId-serviceId',
  'enterprise-orders',
  'enterprise-members',
  'enterprise-invitations',
  'business-projects',
  'business-projects-id',
  'business-settings-corporation'
])

const DISPLAY_CHAT_PAGE_NAME_FOR_BUSINESS_ACCOUNT = Object.freeze([
  'message',
  'talkrooms-talkroomId',
  'mypage-orders-required',
  'mypage-orders-requests',
  'mypage-orders-open',
  'mypage-orders-closed',
  'business-settings-invoice'
])

export default {
  computed: {
    ...mapState('auth/user', ['isBusiness']),
    ...mapGetters('ui', ['hasDeviceJudged', 'isTalkroomPC']),
    ...mapGetters('auth', ['isLoggedIn', 'isFromCoconalaApp'])
  },
  async mounted() {
    /**
     * 通常アカ、ビジアカともに基本非表示（表示画面を個別に対応）
     */
    if (!this.isLoggedIn || this.isFromCoconalaApp) return

    //法人機能利用オンユーザーにだけ表示させる
    if (!this.isBusiness && DISPLAY_CHAT_PAGE_NAME_FOR_BUSINESS_ACCOUNT.includes(this.$route.name))
      return

    // Brazeが表示されるまで待つ
    await this.$util
      .waitUntilWithLimit(() => !!document.querySelector('.ab-iam-root'), 600)
      .catch(() => {})

    // Brazeが表示されていたらチャットサポートは非表示
    if (document.querySelector('.ab-iam-root')) {
      // CSR遷移時に既にチャットサポートが表示されていたら非表示にする
      if (typeof zE === 'function') {
        zE('messenger', 'hide')
      }
      return
    }

    if (typeof zE === 'function') {
      // 既にscriptが読み込まれている場合は配置設定を更新して再表示
      await this.setZendeskWidgetVerticalPosition()
      zE('messenger', 'show')
      return
    }

    try {
      // チャットサポート対象ユーザかどうかの判定
      const { UserAccessor } = await import('~/api/accessors/profile/user-accessor')
      const { canUseChatSupport } = await new UserAccessor(this.$store.state).getCanUseChatSupport()
      if (!canUseChatSupport) return
    } catch (e) {
      if (![grpc.Code.Unknown, grpc.Code.Unauthenticated].includes(e.grpcCode)) {
        this.$sentry.captureException(e)
      }
      return
    }

    const script = document.createElement('script')
    script.src = process.env.config.zendeskWidgetScript
    script.id = 'ze-snippet'
    document.head.appendChild(script)

    script.onload = () => {
      this.setZendeskWidgetVerticalPosition()
    }
  },
  methods: {
    async setZendeskWidgetVerticalPosition() {
      const getZendeskWidget = () => document.getElementById('launcher')
      await this.$util.waitUntilWithLimit(() => getZendeskWidget(), 1000)
      const zendeskWidget = getZendeskWidget()
      if (zendeskWidget) {
        zendeskWidget.style.bottom = await this.getMobileVertical()
      }
    },
    async getMobileVertical() {
      // SP,TBで個別にチャットボタンの配置調整が必要な画面の配置位置取得（marginを除いて画面下部からの高さ）
      switch (this.$route.name) {
        case 'services-id': {
          await this.$util.waitUntil(() => this.hasDeviceJudged) // デバイスでDOM表示切り替えしている箇所がある為、判定を待つ
          const serviceDetailPurchaseSp = document.getElementsByClassName(
            'c-serviceDetailPurchaseSp'
          )
          const serviceDetailPurchaseSpHeight = serviceDetailPurchaseSp.length
            ? serviceDetailPurchaseSp[0].clientHeight
            : 0
          const spTabButton = document.getElementsByClassName('c-spTab_button')
          const spTabButtonHeight = spTabButton.length ? spTabButton[0].clientHeight : 0
          return `${serviceDetailPurchaseSpHeight + spTabButtonHeight}px`
        }
        case 'users':
        case 'users-search': {
          const verticalDrawer = document.getElementsByClassName('c-verticalDrawer')
          const verticalDrawerHeight = verticalDrawer.length ? verticalDrawer[0].clientHeight : 0
          return `${verticalDrawerHeight}px`
        }
        case 'users-id': {
          await this.$nextTick()
          const conversionButton = document.getElementsByClassName(
            'd-profileConversionButton_inner'
          )
          if (!conversionButton.length) return '0px'
          const conversionButtonStyle = window.getComputedStyle(conversionButton[0])
          const conversionButtonPosition = conversionButtonStyle.getPropertyValue('position')
          const conversionButtonpHeight =
            conversionButtonPosition === 'fixed' ? conversionButton[0].clientHeight : 0
          return `${conversionButtonpHeight}px`
        }
        case 'customize-requests-add-userId':
        case 'customize-requests-add-userId-serviceId': {
          await this.$util.waitUntil(() => this.hasDeviceJudged) // デバイスでDOM表示切り替えしている箇所がある為、判定を待つ
          const customizeRequestAddFooter = document.getElementsByClassName(
            'c-customizeRequestAddFooter_fixedButton'
          )
          const customizeRequestAddFooterHeight = customizeRequestAddFooter.length
            ? customizeRequestAddFooter[0].clientHeight
            : 0
          return `${customizeRequestAddFooterHeight}px`
        }
        case 'talkrooms-talkroomId': {
          await this.$util.waitUntil(() => this.hasDeviceJudged)
          if (this.isTalkroomPC) return '0px'
          const messageForm = document.getElementsByClassName('d-messageForm')
          const messageFormHeight = messageForm.length ? messageForm[0].clientHeight : 0
          return `${messageFormHeight}px`
        }
        default:
          return '0px'
      }
    }
  },
  beforeDestroy() {
    if (!this.isLoggedIn || this.isFromCoconalaApp) return

    // CSR遷移時に表示したままになるよう遷移先が表示ページの場合は一旦非表示にしない
    // beforeDestroy時のthis.$routeは遷移先
    if (
      !(
        DISPLAY_CHAT_PAGE_NAME.includes(this.$route.name) ||
        (DISPLAY_CHAT_PAGE_NAME_FOR_BUSINESS_ACCOUNT.includes(this.$route.name) && this.isBusiness)
      ) &&
      typeof zE === 'function'
    ) {
      zE('messenger', 'hide')
    }
  }
}
