<template>
  <div class="c-headBanner">
    <a
      :href="registrationURL"
      v-if="couponType === 'serviceText'"
      class="c-headBanner_serviceText"
    >
      <div class="c-headBannerSearchServiceText">
        <div>
          {{ $t('components.head_banner.message') }}
          <span class="c-headBannerSearchServiceText_notice">
            {{ $t('components.head_banner.exception') }}
          </span>
        </div>
      </div>
    </a>

    <a
      :href="registrationURL"
      v-if="couponType === 'serviceText'"
      class="c-headBanner_serviceTextSp"
    >
      <div class="c-headBannerSearchServiceText c-headBannerSearchServiceText-sp">
        <div>{{ $t('components.head_banner.message') }}</div>
      </div>
    </a>

    <div
      class="c-headBannerServiceDetailPhonePc"
      v-if="couponType === 'serviceDetailPhone'"
    >
      <a :href="$coconala.registrationURL(null, { ref: 'pservices' })">
        <img
          src="@/assets/images/common/banner/phone_coupon_header_pc.png"
          alt="電話相談サービスで最大30分使える初回3000円無料クーポンプレゼント"
        />
      </a>
    </div>
    <coupon-banner v-if="couponType === 'myCoupon'" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import CouponBanner from './components/CouponBanner'

export default {
  name: 'headBanner',
  components: {
    CouponBanner
  },
  props: {
    // 必要に応じて追加していく
    serviceClass: {
      type: Number,
      default: null
    },
    pageType: {
      type: String,
      default: ''
    },
    parentCategoryId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState('master', ['categoryDivinationId']),
    ...mapState('pages/search/search', ['searchCondition']),
    ...mapGetters('auth', ['isLoggedIn']),
    couponType() {
      if (this.pageType === 'businessHome' && this.isLoggedIn && this.hasCoupon) {
        // ビジネストップ用クーポン
        return 'myCoupon'
      } else if (this.pageType === 'home') {
        // Home用クーポン
        if (this.isLoggedIn) {
          if (this.hasCoupon) {
            return 'myCoupon'
          }
          return ''
        }
        // Homeクーポン（PC, TB）
        return 'serviceText'
      }

      // Home以外共通 ログイン時はクーポンを出さない
      if (this.isLoggedIn) {
        return ''
      }

      // ビジネスプレビューでは表示しない
      if (this.$route.query.is_business) {
        return ''
      }

      // サービス詳細ページ用クーポン
      if (this.pageType === 'serviceDetail') {
        if (this.serviceClass === this.$store.state.constants.serviceClass.phone) {
          // サービス詳細（電話占い）クーポン（PC）（height確保のためSPはcssで消している）
          if (this.parentCategoryId === this.categoryDivinationId) {
            return 'serviceDetailPhone'
          }
          return ''
        }

        // サービス詳細（電話占い以外）クーポン（PC, TB）
        return 'serviceText'
      }

      // サービス検索ページ用クーポン
      if (this.pageType === 'serviceSearch') {
        // サービス検索（電話以外）クーポン（PC, TB）
        return 'serviceText'
      }
    },
    /** クーポン保有判定 */
    hasCoupon() {
      const coupon = this.$store.state.my.activities.userHeader.coupon
      return (
        coupon &&
        coupon.discount > 0 &&
        this.$util.dayjs.unix(coupon.expireDate).isAfter(new Date())
      )
    },
    registrationURL() {
      switch (this.pageType) {
        case 'home':
          return this.$coconala.registrationURL(null, { ref: 'top_register_title' })
        case 'serviceDetail':
          return this.$coconala.registrationURL(null, { ref: 'service_register_title' })
        case 'serviceSearch':
          return this.$coconala.registrationURL(this.$route.fullPath, {
            ref: 'category_register_title',
            ...(this.searchCondition.masterCategoryId
              ? { master_category_id: this.searchCondition.masterCategoryId }
              : {}),
            ...(this.searchCondition.serviceKind
              ? { service_kind: this.searchCondition.serviceKind }
              : {})
          })
        default:
          return this.$coconala.registrationURL()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-headBanner {
  &_serviceTextSp {
    display: none;
  }
}
.c-headBannerSearchServiceText {
  display: flex;
  width: 100%;
  height: 36px;
  background-color: $color-orange-400;
  color: $color-white;
  font-weight: bold;
  font-size: 20px;

  align-items: center;
  justify-content: center;

  &-sp {
    font-size: 14px;
  }

  &_notice {
    font-size: 12px;
  }
}

.c-headBannerServiceDetailPhonePc {
  @include link-effect;

  margin: 0 auto;
  padding: 12px 20px;
  width: 1080px;
  height: 90px;
  text-align: center;

  a {
    display: block;
  }

  img {
    width: 1040px;
    height: 66px;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-headBannerServiceDetailPhonePc {
    display: none;
  }
  .c-headBanner {
    &_serviceText {
      display: none;
    }
    &_serviceTextSp {
      display: block;
    }
  }
}
</style>
