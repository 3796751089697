import { computed, ref, useContext } from '@nuxtjs/composition-api'

/** 上部アプリバナー非表示クッキーキー */
export const APP_COOKIE_KEY = 'smart_app_banner_hidden'

export const useAppBanner = () => {
  const { $ua, $cookies, route } = useContext()
  /** アプリバナークローズフラグ */
  const isClosedAppBanner = ref(false)
  /** アプリバナーの表示制御 */
  const showAppBanner = computed<boolean>(() => {
    return (
      $ua.isFromSmartphone() &&
      $cookies.get(APP_COOKIE_KEY) !== true &&
      !route.value.query.from_ad &&
      !isClosedAppBanner.value
    )
  })
  const closeAppBanner = () => {
    isClosedAppBanner.value = true
    $cookies.set(APP_COOKIE_KEY, 'true', {
      path: '/',
      maxAge: 60 * 60 * 24 * 7
    })
  }

  return { showAppBanner, isClosedAppBanner, closeAppBanner }
}
